<template>
<div>
 <a-modal :visible="visible" title="交车信息" footer="" @cancel="cancel">
  <div>到收款金额：<span class="color-R">{{ amtNum }}</span></div>
   <div  class="m-t1">收款对象：{{lookDeliveryInfo.deliveryType?.label}}</div>
   <div class="m-t1" v-if="lookDeliveryInfo.officeOrgName">办事处名称：{{lookDeliveryInfo.officeOrgName}}</div>
   <div class="m-t1">是否到达办事处：{{lookDeliveryInfo.deliveryOfficeOrg?.label  }}</div>
   <div class="m-t1">交车条： <a-image :width="100" style="height:100px" :src="lookDeliveryInfo.fileUrl"  @click="handlePreview(lookDeliveryInfo)" /></div>
   <div class="m-t1">交车人：{{lookDeliveryInfo.creator}}</div>
   <div class="m-t1">交车时间：{{lookDeliveryInfo.createTime}}</div>
   <div class="m-t1">交车说明：{{ lookDeliveryInfo.remark }}</div>
 </a-modal>
</div>
</template>

<script setup>
import { ref } from 'vue'
const emit = defineEmits(['update:visible'])
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  lookDeliveryInfo:{
    type: Object,
    default:{}
  },
  amtNum:{
    type: Number,
    default: 0
  }
 })
const handlePreview = (item) => {
      item.fileUrl = item.fileRealUrl
    }

const cancel = () =>{
  emit('update:visible',false)
}
</script>

<style lang="less" scoped>

</style>