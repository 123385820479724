<template>
  <div>
    <ly-searchList :iptData="iptData" @searchAdd="searchAdd" @resetBtn="resetBtn" />
    <div class="m-b1 m-t1">
      <ly-button @click="newAddCar" class="m-l1" type="primary" style="background: #396AFC;border-color:#396AFC" :loading="false">
        批量交车
      </ly-button>
      <ly-button @click="newAdd" class="m-l1" type="primary" style="background: #396AFC;border-color:#396AFC" :loading="false">
        批量附加费
      </ly-button>
    </div>
    <div class="m-t1 tableBox">
      <ly-table :columns="columns" size="small" rowKey="orderVehicleId" :scroll="{ x: 2300 }" @onChange="onChange" :rowSelectionShow="true" :data="data" :pagination="pagination" @pageChange="pageChange" :loading="loading">
        <template #brand="{ record }">
          <a @click="smallCar(record)">
            <div class="flex al-center ju-center">
              <div class="isExpatriate" v-if="record.orderSource.value === 9">
                华月
              </div>
              <div>{{ record.vinNo }}</div>
            </div>{{ record.brand }}{{ record.model }}
          </a>
        </template>
        <template #vehicleTransStatus="{record }">
          <span :style="{color:colorList[record.vehicleTransStatus?.value]}">{{  record.vehicleTransStatus?.label  }}</span>
        </template>
        <template #startAddress="{ record }">
          <div> {{   record.startAddress }}</div>{{ record.endAddress}}
        </template>
        <template #addContent="{ record }">
          <a @click="openSur(record)">附加费</a>
          <a class="m-l1" @click="CollectionAdd(record)" v-if="record.isPeerCollection?.value === 1">代收码</a>
          <a @click="newCar(record)" v-if="record.vehicleTransStatus.value === 9&&record.isDelivery.value === 0" class="m-l1 ">交车</a>
          <a @click="lookCar(record)" v-if="record.vehicleTransStatus.value === 9&&record.isDelivery.value === 1" class="m-l1 ">查看</a>
          <a @click="vehQRC(record)" v-if="!record.isPeerCollection || record.isPeerCollection?.value === 0" class="m-l1 ">生成支付码</a>
        </template>
      </ly-table>
    </div>
    <!-- 批量交车 -->
    <ly-modal v-model:visible="vehVisible" :title="title" :footer="false" width="30%" v-if="vehVisible" >
      <div>
        <div>已选车辆 {{ selectedData.length }}</div>
        <div v-for="(item,index) in selectedData" :key="item.id" class="itemBox flex al-center ju-between">
          <div class="flex">
            <div style="min-width:100px">{{ item.vinNo }}</div><span>到收金额：{{item.paidAmt}}</span>
          </div>
          <a-popconfirm title="确定移除吗?" ok-text="确定" cancel-text="取消" @confirm="confirm(item,index)">
            <a v-if="selectedData.length>1">移除</a>
          </a-popconfirm>
        </div>
      </div>
      <ly-formList ref="VehRef" v-if="vehVisible" :fromList="vehFormList" @cancel="vehVisible = false" @getVal="getVal">
        <template #head>
          <a-form ref="formRef" :model="fromOpt" :rules="rulesOpt">
            <a-form-item name="deliveryType" label="收款对象">
              <a-select style="width:33%" v-model:value="fromOpt.deliveryType" placeholder="请选择">
                <a-select-option v-for="opt in recipientOfPay" :key="opt.value" :value="opt.value">{{ opt.label }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item v-if="fromOpt.deliveryType === 2" name="officeOrgId" label="选择办事处">
              <a-select style="width:33%" @select="selectOrg" v-model:value="fromOpt.officeOrgId" placeholder="请选择">
                <a-select-option v-for=" item in $store.state.app.orgTypeList" :label="item.name" :key="item.id">{{ item.name
                }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item name="deliveryOfficeOrg" label="是否到达办事处">
              <a-select style="width:33%" v-model:value="fromOpt.deliveryOfficeOrg" placeholder="请选择">
                <a-select-option :value="1">是</a-select-option>
                <a-select-option :value="0">否</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item name="fileId" label="交车条">
              <ly-upload ref="uploadRef" :maxMun="1" @fileStatus="fileStatus"></ly-upload>
            </a-form-item>
          </a-form>
        </template>
      </ly-formList>
    </ly-modal>
    <!-- 查看交车信息 -->
    <DeliveryInfo v-model:visible="visibleInfo" v-if="visibleInfo" :amtNum="amtNum" :lookDeliveryInfo="lookDeliveryInfo"></DeliveryInfo>
    <!-- 批量附加费 -->
    <ly-modal v-model:visible="surchargeVisible" :title="title" :footer="false" width="30%" v-if="surchargeVisible">
      <div>
        <div>已选车辆 {{ selectedData.length }}</div>
        <div v-for="(item,index) in selectedData" :key="item.id" class="itemBox flex al-center ju-between">
          <div class="flex">
            <div style="min-width:100px">{{ item.vinNo }}</div>
          </div>
          <a-popconfirm title="确定移除吗?" ok-text="确定" cancel-text="取消" @confirm="confirm(item,index)">
            <a v-if="selectedData.length>1">移除</a>
          </a-popconfirm>
        </div>
      </div>
      <ly-formList ref="formListRef" @select="select" @cancel="surchargeVisible = false" v-if="surchargeVisible" :maxMun="1" :fromList="surFormList" @getVal="getValSur" @search="search">
      </ly-formList>
    </ly-modal>
    <!-- 支付码 -->
    <PayQRC ref="payQRCref" />
    <!-- 小车信息 -->
    <SmallVehicle v-model:smallVisible="smallVisible" @success="success" v-if="smallVisible" :orderVehicleId="orderVehicleId"></SmallVehicle>
    <!-- 付款码 -->
    <PayModal ref="payQRCrefModal" />
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { iptData, columns, vehFormList, surFormList } from './smallVehicle'
import { vehiclePage, batchDelivery, batchvehicleFee, deliveryInfo, driverList } from '@/api/order/order'
import { message } from 'ant-design-vue';
import { recipientOfPay } from "@/utils/enum.js";
import { pageList } from '@/api/crmManagement/crm'
import { debounce, transitionTimeYMD, addressTran } from '@/utils/util.js';
import { cloneDeep } from 'lodash-es';
import PayQRC from '@/components/payQRC'
import DeliveryInfo from './components/deliveryInfo.vue';
import SmallVehicle from '@/views/transport/vehicle/components/smallVehicle.vue';
import PayModal from '@/views/transport/components/payModal'

const payQRCrefModal = ref(null)
const payQRCref = ref(null)
const loading = ref(false)
const vehVisible = ref(false)
const data = ref([])
const selectedList = ref([])
const selectedData = ref([])
const formRef = ref(null)
const uploadRef = ref(null)
const title = ref('')
const surchargeVisible = ref(false)
const formListRef = ref(null)
const VehRef = ref(null)
const searchMsg = ref({})
const visibleInfo = ref(false)
const lookDeliveryInfo = ref({})
const amtNum = ref(0)
const smallVisible = ref(false)
const orderVehicleId = ref('')
const fromOpt = ref({
  deliveryType: null,
  officeOrgId: null,
  officeOrgName: '',
  deliveryOfficeOrg: null,
  fileId: 1
})
const rulesOpt = ref({
  deliveryType: [{ required: true, message: '请选择收款对象' }],
  officeOrgId: [{ required: true, message: '请选择办事处' }],
  deliveryOfficeOrg: [{ required: true, message: '请选择' }],
  fileId: [{ required: true, message: '请上传', type: 'number', trigger: 'blur' }]
})
const pagination = ref({
  current: 1,
  pageSize: 10,
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total, range) => `共 ${total} 条`
})
const state = ref({
  timer: null,
})
const colorList = ref({
  1: '#d48310',
  3: '#396afc',
  4: '#d48310',
  5: '#d48310',
  9: '#099858'
})

const selectOrg = (e, v) => {

  fromOpt.value.officeOrgName = v.label
}
//查看小车信息
const smallCar = (record) => {
  orderVehicleId.value = record.orderVehicleId
  smallVisible.value = true
}

// 代收码
const CollectionAdd = (item) => {
  let obj = {
    startAddress: item.startAddress,
    endAddress: item.endAddress,
    taskId: item.taskId,
    orderId: item.orderId,
    payType: 1,
    vinNo:item.vinNo
  } 
  setTimeout(() => {
    payQRCrefModal.value.openWindow(obj)
  }, 10)
}
// 单辆车的支付码
const vehQRC = (record) => {
  let obj = {
    startAddress: record.startAddress,
    endAddress: record.endAddress,
    frId: '1',
    orderId: record.orderId,
    payType: '1',
    ovId: record.orderVehicleId,
    vehicleList: record.vinNo
  }
  setTimeout(() => {
    payQRCref.value.openWindow(obj)
  }, 10)
}

//查看交车信息
const lookCar = (record) => {
  deliveryInfo(record.orderVehicleId).then(res => {
    if (res.code !== 10000) return
    lookDeliveryInfo.value = res.data
    amtNum.value = record.paidAmt
    visibleInfo.value = true
  })

}

const searchAdd = (e) => {
  searchMsg.value = cloneDeep(e)
  searchMsg.value.orderTimeStart = transitionTimeYMD(searchMsg.value.orderTimeStart)
  searchMsg.value.orderTimeEnd = transitionTimeYMD(searchMsg.value.orderTimeEnd)
  searchMsg.value.unloadTimeStart = transitionTimeYMD(searchMsg.value.unloadTimeStart)
  searchMsg.value.unloadTimeEnd = transitionTimeYMD(searchMsg.value.unloadTimeEnd)
  searchMsg.value.startAddress = addressTran(searchMsg.value.startAddress)
  searchMsg.value.endAddress = addressTran(searchMsg.value.endAddress)
  pagination.value.current = 1
  reqVehicleList()
}
//重置
const resetBtn = () => {
  pagination.value.current = 1
  searchMsg.value = {}
  reqVehicleList()
}
const success = () => {
  reqVehicleList()
}
const fileStatus = (e) => {
  VehRef.value.fileLoading = e
}

//搜索框
const searchVal = ref('')
const search = (e) => {
  searchVal.value = e
  debounce(state, reqPage, 1000)
}
const reqPage = () => {
  pageList({ name: searchVal.value }).then(res => {
    if (res.code !== 10000) return
    let list = []
    res.data.forEach(item => {
      list.push(
        {
          label: item.name,
          value: item.id
        }
      )
    })
    surFormList.value[1].opt = list
  })
}
// 附加费
const getValSur = (e, ids) => {
  formListRef.value.fileLoading = true
  let val = cloneDeep(e)
  val.fileId = ids.map(item => item.id)[0]
  val.orderVehicleIds = selectedData.value.map(item => item.orderVehicleId)
  batchvehicleFee(val).then(res => {
    if (res.code !== 10000) return
    message.success('操作成功')
    surchargeVisible.value = false
    reqVehicleList()
  }).finally(() => {
    if( formListRef.value){
      formListRef.value.fileLoading = false
    }
  })
}
//车辆表单获得值
const getVal = (e) => {
  formRef.value
    .validate().then(() => {
      if (uploadRef.value.state.fileIds.length === 0) {
        message.warn('请上传交车条')
        return
      }
      VehRef.value.fileLoading = true
      batchDelivery({
        orderVehicleIds: selectedData.value.map(item => item.orderVehicleId),
        deliveryType: fromOpt.value.deliveryType,
        officeOrgId: fromOpt.value.officeOrgId,
        officeOrgName: fromOpt.value.officeOrgName,
        deliveryOfficeOrg: fromOpt.value.deliveryOfficeOrg,
        fileId: uploadRef.value.state.fileIds.map(item => item.id)[0],
        remark: e.remark
      }).then((res) => {
        if (res.code !== 10000) return
        message.success('操作成功')
        vehVisible.value = false
        reqVehicleList()
      }).finally(() => {
        if(VehRef.value){
          VehRef.value.fileLoading = false
        }
      })
    })

}
const select = (e) => {
  driverList({
    customerId: e.value
  }).then(res => {
    if (res.code !== 10000) return
    surFormList.value[2].opt = res.data.map(item => {
      return {
        label: item.name,
        value: item.id
      }
    })
  })
}
//移除
const confirm = (item, index) => {
  selectedData.value.splice(index, 1)
  let idx = ''
  data.value.forEach((opt, index) => {
    if (opt.orderVehicleId === item.orderVehicleId) {
      idx = index
    }
  })
  selectedList.value.splice(selectedList.value.indexOf(idx), 1)
}
const onChange = (e) => {
  selectedList.value = e
}
// 附加费
const openSur = (record) => {
  selectedData.value = [record]
  title.value = '附加费'
  surchargeVisible.value = true
  surFormList.value[1].opt = []
  surFormList.value[2].opt = []
}
// 批量附加费
const newAdd = () => {
  if (selectedList.value.length === 0) {
    message.warn('请选择批量附加费的车辆');
    return
  }
  let list = []
  selectedList.value.forEach(opt => {
    data.value.forEach(item => {
      if (item.orderVehicleId === opt) {
        list.push(item)
      }
    })
  })
  selectedData.value = list
  title.value = '批量附加费'
  surchargeVisible.value = true
  surFormList.value[1].opt = []
  surFormList.value[2].opt = []
}
// 单个交车
const newCar = (record) => {
  selectedData.value = [record]
  vehVisible.value = true
  title.value = '交车'
  for (let key in fromOpt.value) {
    fromOpt.value[key] = null
  }
  fromOpt.value.fileId = 1
}
//批量交车
const newAddCar = () => {
  if (selectedList.value.length === 0) {
    message.warn('请选择批量交车的车辆');
    return
  }
  for (let key in fromOpt.value) {
    fromOpt.value[key] = null
  }
  fromOpt.value.fileId = 1
  let list = []
  selectedList.value.forEach(opt => {
    data.value.forEach(item => {
      if (item.orderVehicleId === opt) {
        list.push(item)
      }
    })
  })
  selectedData.value = list
  vehVisible.value = true
  title.value = '批量交车'
}
const pageChange = (e) => {
  pagination.value = e
  reqVehicleList()
}
const reqVehicleList = () => {
  loading.value = true
  let msg = {
    current: pagination.value.current,
    size: pagination.value.pageSize,
  }
  Object.assign(msg, searchMsg.value)
  vehiclePage(msg).then(res => {
    if (res.code !== 10000) return
    data.value = res.data.records
    pagination.value.total = res.data.total
    console.log(res);
  }).finally(() => {
    loading.value = false
  })
}
onMounted(() => {
  reqVehicleList()
})
</script>

<style lang="less" scoped>
.tableBox {
  padding: 15px;
  background: #ffffff;
  min-height: calc(100vh - 230px);
}
.itemBox {
  margin-top: 4px;
  padding: 4px 5px;
  border: 1px solid rgb(217, 217, 217);
  background: rgb(247, 247, 247);
  color: #6b778c;
  font-size: 12px;
}
.isExpatriate {
  min-width: 20%;
  height: 16px;
  background-color: #001529;
  border-radius: 2px;
  font-size: 10px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
}
</style>